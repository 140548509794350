@font-face {
    font-family: 'DBHelvethaicaX-55Regular';
    src: url('../fonts/DBHelvethaicaX-55Regular.woff2') format('woff2'),
        url('../fonts/DBHelvethaicaX-55Regular.woff') format('woff'),
        url('../fonts/DBHelvethaicaX-55Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-75Bd';
    src: url('../fonts/DBHelvethaicaX-75Bd.woff2') format('woff2'),
        url('../fonts/DBHelvethaicaX-75Bd.woff') format('woff'),
        url('../fonts/DBHelvethaicaX-75Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Medium';
    src: url('../fonts/Prompt-Medium.woff2') format('woff2'),
        url('../fonts/Prompt-Medium.woff') format('woff'),
        url('../fonts/Prompt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-65Med';
    src: url('../fonts/DBHelvethaicaX-65Med.woff2') format('woff2'),
        url('../fonts/DBHelvethaicaX-65Med.woff') format('woff'),
        url('../fonts/DBHelvethaicaX-65Med.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Bold';
    src: url('../fonts/Prompt-Bold.woff2') format('woff2'),
        url('../fonts/Prompt-Bold.woff') format('woff'),
        url('../fonts/Prompt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-45Li';
    src: url('../fonts/DBHelvethaicaX-45Li.woff2') format('woff2'),
        url('../fonts/DBHelvethaicaX-45Li.woff') format('woff'),
        url('../fonts/DBHelvethaicaX-45Li.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma.woff2') format('woff2'),
        url('../fonts/Tahoma.woff') format('woff'),
        url('../fonts/Tahoma.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Regular';
    src: url('../fonts/Prompt-Regular.woff2') format('woff2'),
        url('../fonts/Prompt-Regular.woff') format('woff'),
        url('../fonts/Prompt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-SemiBold';
    src: url('../fonts/Prompt-SemiBold.woff2') format('woff2'),
        url('../fonts/Prompt-SemiBold.woff') format('woff'),
        url('../fonts/Prompt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Light';
    src: url('../fonts/FontAwesome5Pro-Light.woff2') format('woff2'),
        url('../fonts/FontAwesome5Pro-Light.woff') format('woff'),
        url('../fonts/FontAwesome5Pro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Solid';
    src: url('../fonts/FontAwesome5Pro-Solid.woff2') format('woff2'),
        url('../fonts/FontAwesome5Pro-Solid.woff') format('woff'),
        url('../fonts/FontAwesome5Pro-Solid.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Regular';
    src: url('../fonts/FontAwesome5Pro-Regular.woff2') format('woff2'),
        url('../fonts/FontAwesome5Pro-Regular.woff') format('woff'),
        url('../fonts/FontAwesome5Pro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.font-1 {
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
div {
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
.font-c-fff{
    color: #ffffff;
}
samp ,h2 , center,input, label,::placeholder,textarea,span,li{
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
span,li{
    font-size: 20px !important;
}
.font-size-1-5rem{
    font-size: 1.5em;
}
.font-size-2rem{
    font-size: 2em !important;
}
.font-size-20{
    font-size: 20px !important;
}
.font-size-28{
    font-size: 28px !important;
}
.font-big{
    font-size: 5em !important;
}

.font-co-green{
    color: green !important;
}

.bg-fff{
    background-color: #ffffff !important;
}
