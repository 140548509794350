
body{
    /* max-width: 912px !important; */
    margin: auto !important;
}
#fadein{
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
.BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 300px;
    margin-top: 10px;
}
.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color:unset !important;
    height: 75%;
}
.progress-bar{
    height: unset !important;
    border-radius: unset !important;
}
iframe{
    display: none;
}
.swal2-content{
    padding: unset !important;
}

.w-100{
    width: 100% !important;
    padding: 10px;
}
.input-form{
    margin: 15px 0;
    color: #ffffff;
}
.input-form .title-input{
    font-size: 20px;
}
.input-form > input{
    width: 100%;
    height: 50px;
    font-size: 30px;
    /* padding-left: 20px; */
    background-color: unset !important;
    outline: unset;
    border:solid 1px #000000;
    border-radius: 50px;
    color: #081c97;
    background-color: #ffffff !important;
    text-align:center;
    
}
.disabel{
    background-color: gray !important;
}

.swal2-popup{
    padding: 0.25em !important; 
}
#sub-share{
    display: flex;
    justify-content: center;
}
ol, ul {
    padding-left: 0rem !important;
}
hr{
    border: 1px solid red;
}
span, li {
    font-size: 20px !important;
    margin: 0 1px;
}
.swal2-backdrop-show{
    z-index: 2000 !important;
}
/* .app-conten input{
    font-size: 2em;
} */


.load-screen {
    width: 100%;
    height: 100%;
    /* background: url("./asset/images/neeyai/octo-loader.gif") no-repeat center center #fff; */
    position: fixed;
    opacity: 0.5;
    background-size: contain;
    /* background-color: #000000; */
    z-index: 1000;
}




/* @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
} */


@media (max-width: 575.98px) {
    .content-group-btn{
        position: fixed;
        z-index: 15;
        bottom: 0;
        top: unset;
    }
    .content-group-btn img{
        display: inline-block;
        width: 50%;
    }

    .cover-profile{
        height:200px !important;
      }
}

@media (max-width: 767.98px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 1199.98px) {

}

.rcv-product-image-container-custom > img{
    height: unset !important;
}

.rcv-product-image-container-custom{
    width: unset !important;
}

.rcv-product-name-custom{
    color: #000000 !important;
}

.rcv-product-description-custom{
    color: #636363 !important;
    overflow: unset !important;
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    /* background: #373a47; */
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: whitesmoke;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  input:focus {
    background-color: unset
  }
  a:focus {
    background-color: unset;
    outline: unset;
  }
  .menu-item{
      color: #ef6184;
      font-size: 20px;
      text-decoration: unset;
  }
  .text-center-not{
      margin-top: 10px;
      margin-bottom: 10px;
      color: #cccccc;
  }
  .cover-size-img{
    width: 163px;
    height: 163px;
    object-fit: cover;
  }
  a{
    color: unset !important;
  }

  .MuiPaper-elevation4 {
    box-shadow: unset !important;
}

.title-text-h2{
    font-size: 30px;
    font-weight: 500;
    color: #000000;
}
.all-icon-cr{
    color: #00cbc3;
}
.fix-size-content{
    max-width: 912px;
    margin: auto;
}
.img-c-w100{
    width: 100%;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
}
.dev-coin{
    background-color: #ff8205;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 50px;
    color: #ffffff !important;
}