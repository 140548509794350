body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./asset/images/8071.jpg'); */
  overflow:  unset !important;
}

.block-ui{
  position: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 .act{
   background-color: #cccccc !important;
 }

 .sk-hide{
   display: none !important;
 }



 #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

.dtw{
  background-color: #ef6184;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  text-align: center;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}

body{
    /* max-width: 912px !important; */
    margin: auto !important;
}
#fadein{
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
.BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 300px;
    margin-top: 10px;
}
.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color:unset !important;
    height: 75%;
}
.progress-bar{
    height: unset !important;
    border-radius: unset !important;
}
iframe{
    display: none;
}
.swal2-content{
    padding: unset !important;
}

.w-100{
    width: 100% !important;
    padding: 10px;
}
.input-form{
    margin: 15px 0;
    color: #ffffff;
}
.input-form .title-input{
    font-size: 20px;
}
.input-form > input{
    width: 100%;
    height: 50px;
    font-size: 30px;
    /* padding-left: 20px; */
    background-color: unset !important;
    outline: unset;
    border:solid 1px #000000;
    border-radius: 50px;
    color: #081c97;
    background-color: #ffffff !important;
    text-align:center;
    
}
.disabel{
    background-color: gray !important;
}

.swal2-popup{
    padding: 0.25em !important; 
}
#sub-share{
    display: flex;
    justify-content: center;
}
ol, ul {
    padding-left: 0rem !important;
}
hr{
    border: 1px solid red;
}
span, li {
    font-size: 20px !important;
    margin: 0 1px;
}
.swal2-backdrop-show{
    z-index: 2000 !important;
}
/* .app-conten input{
    font-size: 2em;
} */


.load-screen {
    width: 100%;
    height: 100%;
    /* background: url("./asset/images/neeyai/octo-loader.gif") no-repeat center center #fff; */
    position: fixed;
    opacity: 0.5;
    background-size: contain;
    /* background-color: #000000; */
    z-index: 1000;
}




/* @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
} */


@media (max-width: 575.98px) {
    .content-group-btn{
        position: fixed;
        z-index: 15;
        bottom: 0;
        top: unset;
    }
    .content-group-btn img{
        display: inline-block;
        width: 50%;
    }

    .cover-profile{
        height:200px !important;
      }
}

@media (max-width: 767.98px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 1199.98px) {

}

.rcv-product-image-container-custom > img{
    height: unset !important;
}

.rcv-product-image-container-custom{
    width: unset !important;
}

.rcv-product-name-custom{
    color: #000000 !important;
}

.rcv-product-description-custom{
    color: #636363 !important;
    overflow: unset !important;
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    /* background: #373a47; */
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: whitesmoke;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  input:focus {
    background-color: unset
  }
  a:focus {
    background-color: unset;
    outline: unset;
  }
  .menu-item{
      color: #ef6184;
      font-size: 20px;
      text-decoration: unset;
  }
  .text-center-not{
      margin-top: 10px;
      margin-bottom: 10px;
      color: #cccccc;
  }
  .cover-size-img{
    width: 163px;
    height: 163px;
    object-fit: cover;
  }
  a{
    color: unset !important;
  }

  .MuiPaper-elevation4 {
    box-shadow: unset !important;
}

.title-text-h2{
    font-size: 30px;
    font-weight: 500;
    color: #000000;
}
.all-icon-cr{
    color: #00cbc3;
}
.fix-size-content{
    max-width: 912px;
    margin: auto;
}
.img-c-w100{
    width: 100%;
    border-radius: 10px;
    margin: 5px;
    padding: 5px;
}
.dev-coin{
    background-color: #ff8205;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 50px;
    color: #ffffff !important;
}
@font-face {
    font-family: 'DBHelvethaicaX-55Regular';
    src: url(/static/media/DBHelvethaicaX-55Regular.940f480e.woff2) format('woff2'),
        url(/static/media/DBHelvethaicaX-55Regular.3f304e63.woff) format('woff'),
        url(/static/media/DBHelvethaicaX-55Regular.f7153938.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-75Bd';
    src: url(/static/media/DBHelvethaicaX-75Bd.9ff95267.woff2) format('woff2'),
        url(/static/media/DBHelvethaicaX-75Bd.f1f753f1.woff) format('woff'),
        url(/static/media/DBHelvethaicaX-75Bd.240d10f1.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Medium';
    src: url(/static/media/Prompt-Medium.9d12b6a8.woff2) format('woff2'),
        url(/static/media/Prompt-Medium.0709acc4.woff) format('woff'),
        url(/static/media/Prompt-Medium.60a96408.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-65Med';
    src: url(/static/media/DBHelvethaicaX-65Med.525f6849.woff2) format('woff2'),
        url(/static/media/DBHelvethaicaX-65Med.20347fc2.woff) format('woff'),
        url(/static/media/DBHelvethaicaX-65Med.d1350dd1.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Bold';
    src: url(/static/media/Prompt-Bold.d493a670.woff2) format('woff2'),
        url(/static/media/Prompt-Bold.7ef32ce0.woff) format('woff'),
        url(/static/media/Prompt-Bold.3b8e345c.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DBHelvethaicaX-45Li';
    src: url(/static/media/DBHelvethaicaX-45Li.473555f0.woff2) format('woff2'),
        url(/static/media/DBHelvethaicaX-45Li.a6efbd87.woff) format('woff'),
        url(/static/media/DBHelvethaicaX-45Li.e3ee1910.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url(/static/media/Tahoma.06b65122.woff2) format('woff2'),
        url(/static/media/Tahoma.ad14dfb0.woff) format('woff'),
        url(/static/media/Tahoma.bbba82ae.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-Regular';
    src: url(/static/media/Prompt-Regular.46fbd8cb.woff2) format('woff2'),
        url(/static/media/Prompt-Regular.48e20879.woff) format('woff'),
        url(/static/media/Prompt-Regular.7b15446c.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt-SemiBold';
    src: url(/static/media/Prompt-SemiBold.6c746fa4.woff2) format('woff2'),
        url(/static/media/Prompt-SemiBold.2554fa4c.woff) format('woff'),
        url(/static/media/Prompt-SemiBold.2141279f.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Light';
    src: url(/static/media/FontAwesome5Pro-Light.dd900777.woff2) format('woff2'),
        url(/static/media/FontAwesome5Pro-Light.439eaebc.woff) format('woff'),
        url(/static/media/FontAwesome5Pro-Light.6fb48103.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Solid';
    src: url(/static/media/FontAwesome5Pro-Solid.48096f17.woff2) format('woff2'),
        url(/static/media/FontAwesome5Pro-Solid.ce807ec1.woff) format('woff'),
        url(/static/media/FontAwesome5Pro-Solid.0fced2f5.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome5Pro-Regular';
    src: url(/static/media/FontAwesome5Pro-Regular.a756c8fe.woff2) format('woff2'),
        url(/static/media/FontAwesome5Pro-Regular.1e4cc2e3.woff) format('woff'),
        url(/static/media/FontAwesome5Pro-Regular.817f48c2.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.font-1 {
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
div {
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
.font-c-fff{
    color: #ffffff;
}
::-webkit-input-placeholder{
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
:-ms-input-placeholder{
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
::-ms-input-placeholder{
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
samp ,h2 , center,input, label,::placeholder,textarea,span,li{
    font-family: 'DBHelvethaicaX-55Regular' !important;
}
span,li{
    font-size: 20px !important;
}
.font-size-1-5rem{
    font-size: 1.5em;
}
.font-size-2rem{
    font-size: 2em !important;
}
.font-size-20{
    font-size: 20px !important;
}
.font-size-28{
    font-size: 28px !important;
}
.font-big{
    font-size: 5em !important;
}

.font-co-green{
    color: green !important;
}

.bg-fff{
    background-color: #ffffff !important;
}

.fix-m-landing{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

body{font-family:"Open Sans",Helvetica,Arial,sans-serif}.header{height:100px}.bg-dark{background-color:#ef6184 !important}.movie-card{margin-left:10px;margin-bottom:10px;max-width:165px;width:165px}.card-deck{text-align:center}.movie-card{display:inline-block}.movie-card.card{height:265px;cursor:pointer;vertical-align:top}.info-rating{display:flex;width:100%}.info-like{display:flex;position:absolute;width:100%;bottom:0}.icon-red{color:ef6184;font-size:12px}.rating-class{background-color:rgba(255,255,255,.7);width:70%;color:#fff;padding:2px}.info-class{width:30%;background-color:#ef6184;color:#fff;text-align:center;line-height:35px}.rating-class-like{background-color:rgba(255,255,255,.7);width:50%;color:#909090;padding:2px;font-size:16px;padding-left:5px}.info-class-like{background-color:rgba(255,255,255,.7);width:50%;color:#909090;padding:2px;font-size:16px}.info-class svg{font-size:10px}.card-body{padding:unset !important;padding:5px !important}.card{padding:0 !important}.movie-card.card2{height:185px;cursor:pointer;vertical-align:top;position:relative;font-family:-apple-system,"Helvetica Neue","Helvetica","Arial","Lucida Grande",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-weight:400;font-weight:400;box-shadow:0 1px 2px rgba(0,0,0,.12);border-radius:8px;background-color:#fff;background-color:#fff;box-sizing:border-box;display:block;margin:8px;padding:0px;text-align:left;word-wrap:break-word}.card-footer-badge{font-size:1rem}.text-justify{text-overflow:ellipsis;display:-webkit-box}
