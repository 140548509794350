.movie-card {
    margin-left: 10px;
    margin-bottom: 10px;
    max-width: 165px;
    width: 165px;
}
.card-deck{
    text-align: center;
}

.movie-card{
    display: inline-block;
}

.movie-card.card {
    height: 265px;
    cursor: pointer;
    vertical-align:top;
}

.info-rating{
    display: flex;
    // position: absolute;
    // top: 0;
    width: 100%;
}

.info-like{
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    // height: 75px;
}

.icon-red{
    color:ef6184;
    font-size: 12px;
}

.rating-class{
    background-color: rgba(255, 255, 255, 0.7);
    width: 70%;
    color: #ffffff;
    padding: 2px;
}
.info-class{
    width: 30%;
    background-color: #ef6184;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
}

.rating-class-like{
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    color: #909090;
    padding: 2px;
    font-size: 16px;
    padding-left: 5px;
}
.info-class-like{
    background-color: rgba(255, 255, 255, 0.7);
    width: 50%;
    color: #909090;
    padding: 2px;
    font-size: 16px;
}

.info-class svg{
    font-size: 10px;
}

.card-body{
    padding: unset !important;
    padding: 5px !important;
}

.card{
    padding: 0 !important;
}

.movie-card.card2 {
    height: 185px;
    cursor: pointer;
    vertical-align:top;
    position: relative;

    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-weight: 400;
    box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
    border-radius: 8px;
    background-color: white;
    background-color: white;
    box-sizing: border-box;
    display: block;
    margin: 8px;
    padding: 0px;
    text-align: left;
    word-wrap: break-word;
}

.card-footer-badge {
    font-size: 1rem;
}
.text-justify{
    text-overflow: ellipsis;
   display: -webkit-box;
//    -webkit-line-clamp: 3; /* number of lines to show */
//            line-clamp: 3; 
//    -webkit-box-orient: vertical;
}